export default [
  {
    title: 'DashBoard',
    route: 'dashboard',
    icon: 'LayersIcon',
  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'LayersIcon',
  },
  {
    header: 'Quản Lý Đăng Ký',
  },
  {
    title: 'Danh Sách Đăng Ký',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Trạng Thái Đăng Ký',
    route: 'orders-status',
    icon: 'CheckCircleIcon',
  },
  {
    header: 'Quản Lý Khách Hàng',
  },
  {
    title: 'Danh Sách Khách Hàng',
    route: 'users',
    icon: 'UserIcon',
  },
  {
    title: 'Nhóm Khách Hàng',
    route: 'users-group',
    icon: 'UsersIcon',
  },
  {
    title: 'Lịch Hẹn Hôm Nay',
    route: 'appointment-today',
    icon: 'CalendarIcon',
  },
  {
    title: 'Lịch Hẹn',
    route: 'appointment-all',
    icon: 'CalendarIcon',
  },
  {
    title: 'Thời Khóa Biểu',
    route: 'schedule-time',
    icon: 'CalendarIcon',
  },
  {
    header: 'Lớp Học',
  },
  {
    title: 'Quản Lý Giáo Viên',
    route: 'manager-teacher',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Quản Lý Trợ Giảng',
    route: 'manager-at',
    icon: 'ChromeIcon',
  },
  {
    title: 'Quản Lý Học Viên',
    route: 'manager-student',
    icon: 'AwardIcon',
  },
  {
    title: 'Quản Lý Phụ Huynh',
    route: 'manager-parent',
    icon: 'FlagIcon',
  },
  {
    title: 'Quản Lý Lớp Học',
    route: 'manager-classes',
    icon: 'PaperclipIcon',
  },
  {
    header: 'Báo Cáo',
  },
  {
    title: 'Báo Cáo Doanh Số',
    route: 'report-revenue',
    icon: 'BarChartIcon',
  },
  {
    title: 'Báo Cáo Q.lý Lớp',
    route: 'report-class',
    icon: 'BookIcon',
  },
  {
    title: 'Báo Cáo Teacher',
    route: 'report-teacher',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Báo Cáo Chung',
    route: 'report',
    icon: 'PieChartIcon',
  },
  {
    title: 'Đơn Hàng Chưa Xử Lý',
    route: 'report-not-action',
    icon: 'ActivityIcon',
  },
  {
    title: 'Báo Cáo Nhân Viên Xử Lý Đơn',
    route: 'report-my-stat',
    icon: 'BarChartIcon',
  },
  {
    title: 'Báo Cáo Tình Trạng Đơn Hàng',
    route: 'report-status',
    icon: 'BarChart2Icon',
  },
  {
    title: 'Báo Cáo Học Viên/Phụ Huynh',
    route: 'report-student-parent',
    icon: 'TrelloIcon',
  },
  {
    header: 'Hồ Sơ',
  },
  {
    title: 'Cài Đặt Chung',
    route: 'settings',
    icon: 'ToolIcon',
  },
  {
    title: 'Quản Trị Nhân Viên',
    route: 'staffs',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Trang Cá Nhân',
    route: 'profile',
    icon: 'UserIcon',
  },
  {
    title: 'Quản Trị Đội Nhóm',
    route: 'groups',
    icon: 'TabletIcon',
  },
  {
    title: 'Quản Lý Quyền',
    route: 'roles',
    icon: 'KeyIcon',
  },
]
