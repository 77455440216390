<template>
  <div>{{ showClock() }}</div>
</template>

<script>
export default {
  methods: {
    showClock() {
      return setInterval(this.clock(), 1000)
    },
    clock() {
      // Khởi tạo đối tượng timer sử dụng Date Object
      const timer = new Date()
      // Gọi các phương thức của đối tượng timer
      let hour = timer.getHours();  // Lấy giờ hiện tại (giá trị từ 0 - 23)
      let minute = timer.getMinutes();  // Lấy phút hiện tại
      let second = timer.getSeconds();  // Lấy giây  hiện tại
      // Thêm ký tự 0 đằng trước nếu giờ, phút, giây < 10 với câu lệnh điều khiển if
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }

      return hour
    },
  },
}
</script>

<style>

</style>